/// <reference path="../../shared_controllers/socketService.ts" />
/// <reference path="../../shared_controllers/util.ts" />
/// <reference path="../../app.ts" />
/// <reference path="../../shared_controllers/common_controllers.ts" />
/// <reference path="../../services/projects.services.ts" />

class projectsVersioningCtrl extends baseEntitiesCtrl {
    static $inject: Array<string> = ['$scope', '$location', '$compile', 'socketService', 'toastr'];
    showsubflows: boolean;
    projectsService: ProjectsServiceClass;
    toastrObj: any;
    backup: any;
    selectedProject: any = {};
    constructor(
        public $scope,
        public $location,
        public $compile,
        public socketService: socketServiceFactory,
        public toastr
    ) {
        super($scope, $location, $compile, socketService);
        this.toastrObj = toastr;
        this.collection = 'workflows';
        this.basequery = '_type eq \'projects-group\'';
        var user: any = this.socketService.user;
        this.projectsService = new ProjectsServiceClass(this.service.socket, this.service.namespace)
        if (!user.roles.find(R => R._id == adminsRoleId) && !user.roles.find(R => R._id == rpaDesignerRoleId) && !user.roles.find(R => R._id == unattendedrobotRoleId)) {
            this.$location.path("/");
            this.$scope.$apply();
        }

        this.socketService.onSignedin(() => {
            this.getProjectsGroupsData();
            //this.loaddata();

        });


    }


    routeToBackups(id){
        this.$location.path("/backups/"+id);
    }

    searchInGroups(){
        this.loading = true;
        this.entities = [];
        this.page = 1; 
        this.getProjectsGroupsData();
        this.loading = false;
    }

    GetProjectsGroupsPage(pageNumber : number){
        this.page = pageNumber; 
        this.getProjectsGroupsData();    
    }
    async getProjectsGroupsData() {
        clearError(this.$scope);
        let query = '';
        if (this.searchstring.trim() !== '') {
              
            query =   "contains(name,'" + this.searchstring + "')" ; 
        }
        this.loading = true;
        this.service = this.socketService.namespace;

        // debugger;
        if (!this.genericServices.isConnected()) return;

        try {
            var Result: any = await this.projectsService.GetProjectsGroups(query,this.pagesize, (this.page - 1) * this.pagesize, this.order.field, this.order.reverse);
            var items = Result.items;
            this.totalItems = Result.count;
            this.pageCount = Math.ceil(Result.count / this.pagesize);  
            this.loading = false; 
            this.entities = items; 
            this.scrollloading = (items.length > 0);
            this.loading = false;

            this.entities = items;

            this.scrollloading = (items.length > 0);
            this.loading = false;

            if (!this.$scope.$$phase) { this.$scope.$apply(); }
        } catch (err) {
            handleError(this.$scope, err);
        }
    }

    GetGroupsData(pageNumber: number) {
        this.page = pageNumber;
        // this.socketService.onSignedin(() => {
        this.getProjectsGroupsData();
        //  });
    }



}